import amplitude from 'amplitude-js';

$(document).on('turbolinks:load', () => {
  amplitude.getInstance().init(BASA_ENV.AMPLITUDE_API_KEY);
  window.amplitude = amplitude;

  window.ampEvent = (name, data) => {
    window.amplitude.getInstance().logEvent(name, data);
  };

  const pageviewElement = document.querySelector('#amplitude-pageview');
  if (pageviewElement !== null) {
    window.ampEvent(
      'page_view',
      {
        page_path: document.location.pathname,
        ...pageviewElement.dataset,
      },
    );
  }
});
